import { Box, Typography, Button, CircularProgress, Tooltip, Popover, Stack, MenuItem, Select } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { InputTextField } from "../../../Inputs/InputTextField";
import { useState, useContext, useEffect } from "react";
import { SnackBarContext } from "../../../../context/snackBarContext";
import {
   CreateNewCompany,
   GetCompanyById,
   getUrlS3,
   useUpdateCompanyPersonDetails,
} from "../../../../lib/usersBEClient";
import { LoadingButton } from "../../../LoadingButton";
import { uploadFileToS3, UseGetPresignedURLUpload } from "../../../../lib/s3Client";
import { companyLegalSchema } from "../../../../lib/Validations/inputSchemas";
import { InputImage } from "../../../Inputs/InputImage";
import { InputFile } from "../../../Inputs/InputFile";
import { ChromePicker } from "react-color";
import { Companies, FileWithDateInterface, Group } from "../../../../types/BaseTypes";
import { ClientCreationContext } from "../../../../context/client/clientCreationContext";

const typeOptions = [
   { value: "natural", text: "Persona Física" },
   { value: "legal", text: "Persona Moral" },
];

interface CompanyDetailsComponentProps {
   group?: Group;
   onComplete: Function;
}

export const CompanyDetailsComponent = (props: CompanyDetailsComponentProps) => {
   const { showSnackBar } = useContext(SnackBarContext);
   const { selectedClient, setSelectedClient, refetch, isLoading } = useContext(ClientCreationContext);
   const selectedCompany = selectedClient.data as Companies;
   const office = selectedClient.type === "office";

   const [isLoadingUpload, setIsLoadingUpload] = useState(false);
   const [companyLogo, setCompanyLogo] = useState<FileWithDateInterface>();
   const [contractFile, setContractFile] = useState<FileWithDateInterface>();
   const { mutate: createMutate, isLoading: isLoadingCreate } = CreateNewCompany();
   const { mutate: updateMutate, isLoading: isLoadingUpdate } = useUpdateCompanyPersonDetails();
   const [isLoadingFiles, setIsLoadingFiles] = useState(!!selectedCompany);
   const [primaryColor, setPrimaryColor] = useState("#162c44");
   const [secondaryColor, setSecondaryColor] = useState("#5d6d7c");
   const [selectedColorPicker, setSelectedColorPicker] = useState("");
   const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
   const open = Boolean(anchorEl);
   const popoverId = open ? "simple-popover" : undefined;

   const handleCancel = () => setSelectedClient(null);

   const fetchAndSetFile = async (
      bucket: string,
      folder: string,
      fileName: string,
      fileType: string,
      setFile: Function
   ) => {
      try {
         const fileUrl = await getUrlS3(bucket, { folder }, fileName);
         const response = await fetch(fileUrl);
         const blob = await response.blob();
         const file = new File([blob], fileName, { type: fileType });
         setFile({ file, lastDateModified: new Date() });
      } catch (error) {
         console.error(`Error fetching file ${fileName}:`, error);
      }
   };

   const getLogo = async (clientId: string) => {
      await fetchAndSetFile("images-lecosy", clientId, "logo.png", "image/png", setCompanyLogo);
   };

   const getContract = async (clientId: string) => {
      await fetchAndSetFile(
         "files-lecosy",
         `contracts/${clientId}`,
         "contract.pdf",
         "application/pdf",
         setContractFile
      );
      setIsLoadingFiles(false);
   };

   const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectedColorPicker(event.currentTarget.id);
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const handleChangeComplete = (color) => {
      if (selectedColorPicker === "color-picker-1") setPrimaryColor(color.hex);
      else setSecondaryColor(color.hex);
   };

   useEffect(() => {
      if (selectedCompany && !companyLogo) getLogo(selectedCompany._id);
      if (selectedCompany && !contractFile) getContract(selectedCompany._id);
      setPrimaryColor((s) => selectedCompany.company_details.primaryColor ?? s);
      setSecondaryColor((s) => selectedCompany.company_details.secondaryColor ?? s);
      // eslint-disable-next-line
   }, [selectedClient]);

   const handleSubmit = async (values) => {
      if (!contractFile || !companyLogo) return showSnackBar("Favor de adjuntar archivos correspondientes", true);

      try {
         if (selectedCompany) {
            updateMutate(
               {
                  person_details: {
                     comercialName: values.comercialName,
                     businessName: values.businessName,
                     activity: values.activity,
                  },
                  companyId: selectedCompany._id,
                  company_details: {
                     id: selectedCompany.company_details._id,
                     primaryColor: primaryColor,
                     secondaryColor: secondaryColor,
                  },
               },
               {
                  onSuccess: async () => {
                     await handleUploadFiles(selectedCompany._id);
                     refetch();
                     showSnackBar("El cliente fue modificado", false);
                  },
                  onError: () => {
                     showSnackBar("Error al modificar el cliente", true);
                  },
               }
            );
         } else {
            createMutate(
               {
                  type: office ? values.clientType : selectedClient.type,
                  person_details: {
                     comercialName: values.comercialName,
                     businessName: values.businessName,
                     activity: values.activity,
                  },
                  company_details: {
                     primaryColor: primaryColor,
                     secondaryColor: secondaryColor,
                  },
                  group: props.group ? props.group._id : null,
                  primaryColor: primaryColor,
                  secondaryColor: secondaryColor,
                  office: office,
               },
               {
                  onSuccess: async (e) => {
                     await handleUploadFiles(e._id);
                     await refetch();
                     const clientObject: any = { type: selectedClient.type, data: await GetCompanyById(e._id) };
                     setSelectedClient(clientObject);
                     props.onComplete();
                     showSnackBar("El cliente fue creado correctamente.", false);
                  },
                  onError: () => {
                     showSnackBar("Error al crear cliente.", true);
                  },
               }
            );
         }
         setIsLoadingUpload(false);
      } catch (error) {
         console.log({ error });
         setIsLoadingUpload(false);

         return;
      }
   };

   const handleUploadFiles = async (clientId: string) => {
      setIsLoadingUpload(true);
      const urlContract = await UseGetPresignedURLUpload({
         bucket: "files-lecosy",
         folder: `contracts/${clientId}`,
         name: "contract.pdf",
      });
      const urlLogo = await UseGetPresignedURLUpload({
         bucket: "images-lecosy",
         folder: clientId,
         name: "logo.png",
      });
      await uploadFileToS3(urlLogo.data, companyLogo.file);
      await uploadFileToS3(urlContract.data, contractFile.file);
      setIsLoadingUpload(false);
   };

   return (
      <Box sx={{ flex: 1 }}>
         {!isLoading && !isLoadingFiles ? (
            <Formik
               initialValues={{
                  businessName: (!isLoading && selectedCompany?.person_details?.businessName) ?? "",
                  comercialName: (!isLoading && selectedCompany?.person_details?.comercialName) ?? "",
                  activity: (!isLoading && selectedCompany?.person_details?.activity) ?? "",
                  primaryColor: (!isLoading && selectedCompany?.company_details?.primaryColor) ?? "#162c44",
                  secondaryColor: (!isLoading && selectedCompany?.company_details?.secondaryColor) ?? "#5d6d7c",
                  clientType: (!isLoading && selectedCompany?.type) ?? "natural",
               }}
               onSubmit={handleSubmit}
               validationSchema={companyLegalSchema}
            >
               {(formProps: FormikProps<any>) => (
                  <Form>
                     <Box
                        sx={{
                           border: 1,
                           borderColor: "#E0E0E0",
                           borderRadius: 2,
                        }}
                     >
                        <Box sx={{ p: 2 }}>
                           <Typography fontWeight={600} variant="h6">
                              {office ? "Datos del despacho" : `Datos de la empresa`}
                           </Typography>
                        </Box>

                        <Box
                           sx={{
                              p: 4,
                              borderTop: 1,
                              borderColor: "#E0E0E0",
                              display: "flex",
                              rowGap: 2,
                              flexDirection: "column",
                              maxHeight: "400px",
                              overflowY: "auto",
                           }}
                        >
                           <Box sx={{ display: "flex", columnGap: 2, alignItems: "baseline" }}>
                              {office && (
                                 <Select
                                    labelId="clientType"
                                    id="clientType"
                                    size="small"
                                    value={formProps.values.clientType}
                                    sx={{ width: 220, height: 40 }}
                                    onChange={(e) => formProps.setFieldValue("clientType", e.target.value)}
                                    defaultValue={formProps.values.clientType}
                                    disabled={selectedCompany !== null}
                                 >
                                    {typeOptions.map((option, index) => (
                                       <MenuItem key={index} value={option.value}>
                                          {option.text}
                                       </MenuItem>
                                    ))}
                                 </Select>
                              )}
                              <InputTextField
                                 sx={{ my: 0.5 }}
                                 variant="outlined"
                                 size="small"
                                 name="businessName"
                                 id="businessName"
                                 type="text"
                                 label="Denominación o razón social"
                                 fullWidth={true}
                              />
                           </Box>

                           <Box sx={{ display: "flex", columnGap: 2 }}>
                              <InputTextField
                                 sx={{ my: 0.5 }}
                                 variant="outlined"
                                 size="small"
                                 name="comercialName"
                                 id="comercialName"
                                 type="text"
                                 label="Nombre comercial"
                                 fullWidth={true}
                              />
                              <InputTextField
                                 sx={{ my: 0.5 }}
                                 variant="outlined"
                                 size="small"
                                 name="activity"
                                 id="activity"
                                 type="text"
                                 label="Actividad y/o giro"
                                 fullWidth={true}
                              />
                           </Box>

                           <Box display={"flex"} alignItems={"center"} columnGap={2}>
                              <Typography>Contrato:</Typography>
                              <InputFile file={contractFile?.file} setFile={setContractFile} keyName="contractFile" />
                           </Box>
                           <Box display={"flex"} columnGap={5}>
                              <Box flex={2.3} display={"flex"} columnGap={3}>
                                 <Typography>Logo de la Empresa:</Typography>
                                 <InputImage
                                    image={companyLogo?.file}
                                    setImage={setCompanyLogo}
                                    keyName="companyLogo"
                                 />
                              </Box>
                              <Box display={"flex"} flex={2} justifyContent={"center"}>
                                 <Stack>
                                    <Typography>Color corporativo:</Typography>
                                    <Stack sx={{ rowGap: 1, mt: 0.5 }}>
                                       <Tooltip title={"Color primario"} placement="right-end">
                                          <Box display={"flex"} sx={{ columnGap: 1, alignItems: "center" }}>
                                             <Button
                                                id="color-picker-1"
                                                aria-describedby={popoverId}
                                                sx={{
                                                   borderRadius: 1,
                                                   border: 1,
                                                   borderColor: "#00000050",
                                                   width: 35,
                                                   height: 20,
                                                   bgcolor: primaryColor,
                                                   ":hover": { bgcolor: primaryColor },
                                                }}
                                                onClick={handleClick}
                                             />
                                             <Typography>{primaryColor}</Typography>
                                          </Box>
                                       </Tooltip>

                                       <Tooltip title={"Color secundario"} placement="right-end">
                                          <Box display={"flex"} sx={{ columnGap: 1, alignItems: "center" }}>
                                             <Button
                                                id="color-picker-2"
                                                aria-describedby={popoverId}
                                                sx={{
                                                   borderRadius: 1,
                                                   border: 1,
                                                   borderColor: "#00000050",
                                                   maxWidth: 35,
                                                   height: 20,
                                                   bgcolor: secondaryColor,
                                                   ":hover": { bgcolor: secondaryColor },
                                                }}
                                                onClick={handleClick}
                                             />
                                             <Typography>{secondaryColor}</Typography>
                                          </Box>
                                       </Tooltip>
                                    </Stack>
                                 </Stack>
                              </Box>
                              <Popover
                                 id={popoverId}
                                 open={open}
                                 anchorEl={anchorEl}
                                 onClose={handleClose}
                                 anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                 }}
                              >
                                 <ChromePicker
                                    color={selectedColorPicker === "color-picker-1" ? primaryColor : secondaryColor}
                                    onChangeComplete={handleChangeComplete}
                                    disableAlpha={true}
                                 />
                              </Popover>
                           </Box>
                        </Box>
                     </Box>

                     <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
                        <Button sx={{ height: "100%" }} variant="outlined" onClick={handleCancel}>
                           Cancelar
                        </Button>
                        <LoadingButton
                           label={selectedCompany ? "Guardar cambios" : "Guardar"}
                           isLoading={isLoadingCreate || isLoadingUpdate || isLoadingUpload}
                        />
                     </Box>
                  </Form>
               )}
            </Formik>
         ) : (
            <Box
               sx={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
               }}
            >
               <CircularProgress size={24} />
            </Box>
         )}
      </Box>
   );
};
